import * as React from "react";
import {
  Layout,
  Typography,
  FloatButton,
  ConfigProvider,
  Spin,
  App as AntApp,
} from "antd";
import { Outlet, useLocation } from "react-router-dom";
import Menu from "./Menu";
import ContattiList from "./ContattiList";

const Fallback = () => (
  <div
    style={{
      position: "absolute",
      zIndex: 10,
      right: 10,
      top: 10,
    }}
  >
    <Spin delay={50} size="small" />
  </div>
);

function App() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <React.Suspense fallback={<Fallback />}>
      <ConfigProvider
        theme={{
          components: {
            Layout: {
              headerBg: "white",
            },
          },
        }}
      >
        <AntApp>
          <Layout.Header
            style={{
              position: "sticky",
              zIndex: 1000,
              top: 0,
            }}
          >
            <Menu />
          </Layout.Header>

          <Layout.Content>
            <Outlet />
            <Layout style={{ padding: 12 }}>
              <ContattiList />
            </Layout>
          </Layout.Content>

          <Layout.Footer
            id="contatti"
            style={{ backgroundColor: "whitesmoke" }}
          >
            <Typography.Text>
              &copy; Dott. Marino Miccini {new Date().getFullYear()}
            </Typography.Text>
          </Layout.Footer>

          <FloatButton.BackTop />
        </AntApp>
      </ConfigProvider>
    </React.Suspense>
  );
}

export default App;
