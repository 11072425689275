import * as React from "react";
import css from "../style.module.css";

const Box = ({ children, style, ...props }) => {
  const innerStyle = React.useMemo(() => {
    return {
      padding: 16,
      borderRadius: 6,
      ...style,
    };
  }, [style]);

  return (
    <div {...props} className={css.glass} style={innerStyle}>
      {children}
    </div>
  );
};

export default Box;
