import * as React from "react";
import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { HomeFilled, EllipsisOutlined } from "@ant-design/icons";

const MainMenu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const menu = React.useMemo(() => {
    /**
     * @type {import('antd').MenuProps['items']}
     */
    return [
      {
        key: "/",
        title: "home",
        label: (
          <HomeFilled
            style={{
              fontWeight: 600,
            }}
          />
        ),
        className: "homepage-memu-item",
      },
      {
        key: "/specializzazioni",
        title: "specializzazioni",
        label: "Specializzazioni",
      },
      {
        key: "/curriculum",
        title: "Curriculum Vitae",
        label: "Curriculum Vitae",
      },
    ];
  }, []);

  const handleClick = React.useCallback(
    (info) => {
      navigate(info.key);
    },
    [navigate]
  );

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[pathname]}
      onClick={handleClick}
      overflowedIndicator={<EllipsisOutlined />}
      items={menu}
    />
  );
};

export default MainMenu;
