import { Descriptions, Row, Col, Button, Typography } from "antd";
import Box from "./components/Box";

const one = [
  {
    key: 1,
    label: "Indirizzo",
    children: (
      <Button
        type="link"
        href="https://maps.app.goo.gl/7N9a5o6Squ4pwKMj9"
        target="_blank"
        rel="noreferrer"
      >
        Viale Portogallo, 3 65015 Montesilvano (PE)
      </Button>
    ),
  },

  {
    key: 2,
    label: "Telefono",
    children: (
      <Button type="link" href={`tel:085 4452495`}>
        085 4452495
      </Button>
    ),
  },
];

const two = [
  {
    key: 1,
    label: "Indirizzo",
    children: (
      <Button
        type="link"
        href="https://maps.app.goo.gl/NYHWqDvZYuHLFgvu9"
        target="_blank"
      >
        Via Ottorino Respighi, 57 63900 Fermo (FM)
      </Button>
    ),
  },

  {
    key: 2,
    label: "Telefono",
    children: (
      <Button type="link" href={`tel:0734 223350`}>
        0734 223350
      </Button>
    ),
  },
];

const three = [
  {
    key: 1,
    label: "Indirizzo",
    children: (
      <Button
        type="link"
        href="https://maps.app.goo.gl/FqBvQZjPUk8TsLHK9"
        target="_blank"
      >
        Via Enaudi 200, 62012 Civitanova Marche (MC)
      </Button>
    ),
    span: 3,
  },

  {
    key: 2,
    label: "Telefono",
    children: (
      <Button type="link" href={`tel:0733 520061`}>
        0733 520061
      </Button>
    ),
    span: 1,
  },
  {
    key: 3,
    label: "Cellulare",
    span: 1,
    children: (
      <Button type="link" href={`tel:391 4852803`}>
        391 4852803
      </Button>
    ),
  },
];

const ContattiList = () => {
  return (
    <Row
      gutter={[16, 16]}
      justify="center"
      style={{ background: "transparent" }}
    >
      <Col lg={8} md={8} xl={8} xxl={8} sm={24} xs={24}>
        <Box style={{ background: "white", height: "100%" }}>
          <Descriptions
            layout="vertical"
            items={one}
            title={
              <Typography.Text
                wrap
                ellipsis={false}
                style={{
                  fontSize: 24,
                  whiteSpace: "wrap",
                }}
              >
                Studio Dentistico Montesilvano
              </Typography.Text>
            }
            size="small"
            colon={false}
            column={1}
          />
        </Box>
      </Col>
      <Col lg={8} md={8} xl={8} xxl={8} sm={24} xs={24}>
        <Box style={{ background: "white", height: "100%" }}>
          <Descriptions
            layout="vertical"
            items={two}
            title={
              <Typography.Text
                wrap
                style={{ fontSize: 24, whiteSpace: "wrap" }}
              >
                Centro Odontoiatrico Villa Verde Plus
              </Typography.Text>
            }
            size="small"
            colon={false}
            column={1}
          />
        </Box>
      </Col>
      <Col lg={8} md={8} xl={8} xxl={8} sm={24} xs={24}>
        <Box style={{ background: "white", height: "100%" }}>
          <Descriptions
            layout="vertical"
            items={three}
            title={
              <Typography.Text
                wrap
                style={{ fontSize: 24, whiteSpace: "wrap" }}
              >
                Clinica Dentale Villa Verde Plus
              </Typography.Text>
            }
            size="small"
            colon={false}
            column={3}
          />
        </Box>
      </Col>
    </Row>
  );
};

export default ContattiList;
