import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Spin } from "antd";
import Layout from "./Layout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        lazy: () => import("./Home"),
      },
      {
        path: "specializzazioni",
        lazy: () => import("./Specializzazioni"),
      },
      {
        path: "curriculum",
        lazy: () => import("./Curriculum"),
      },
    ],
  },
]);

const Router = () => {
  return (
    <RouterProvider
      router={router}
      fallbackElement={<Spin size="large" delay={20} />}
    />
  );
};

export default Router;
